/*body*/
.bsgpsK div:first-child {
    overflow: visible !important;
    white-space: normal !important;
    text-align: center;
}

/*heading*/
.lnndaO{
    overflow-wrap: break-word !important;
    white-space: normal !important;
    text-align: center;
}

.hbotQl {
    /* overflow: hidden; */
    white-space: normal !important;
    overflow-wrap: break-word !important;
}
